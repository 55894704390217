/** ------------------------------
    Multiselect
    ------------------------------ */
.multiselect__tags {
    min-height: var(--form-control-height);
    border-radius: var(--form-control-border-radius);
    border-color: var(--form-control-default-border-color);

    &:focus-within {
        border-color: var(--form-control-default-border-color-active);
        }
    }
    
.multiselect__single {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    }