/** ------------------------------
    Header
    ------------------------------ */
@custom-media --menu-collapsed (width < 768px);
@custom-media --menu-expanded (width >= 768px);

.site-header-container {
    background: #fff;
    color: #444;
    border-bottom: 1px solid #f1f1f1;
    }
    .site-header {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 10px 2vw;
        height: 65px;
        }
    .site-header__logo {
        flex-basis: 220px;
        }

    .site-header__menu {
        flex-grow: 1;
        font-weight: 500;
        }
        .site-header__menu-toggle {
            appearance: none;
            margin-left: auto;
            border: 0;
            background-color: transparent;
            text-transform: uppercase;

            & > svg {
                margin-left: .5em;
                }
            }

    .site-header__nav {
        & a {
            color: currentcolor;
            text-decoration: none;
            }
        }

    .site-header__actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        & > :not(:last-child) {
            margin-right: 10px;
            }
        }

@media (--menu-collapsed) {

    .site-header__menu {
        display: none;
        position: absolute;
        z-index: 99;
        top: 100%;
        margin: 0 -2vw;
        width: 100%;
        border-bottom: 1px solid #f5f6f8;
        background-color: #fff;
        color: #444;
        box-shadow:
            0px 5.1px 4.1px rgba(0, 0, 0, .024),
            0px 16.7px 11.3px rgba(0, 0, 0, .035),
            0px 38.2px 27.1px rgba(0, 0, 0, .046),
            0px 69px 90px rgba(0, 0, 0, .07)
        ;

        &:matches(.is-expanded) {
            display: block;
            }
        }
        .site-header__nav {

            & a {
                display: block;
                padding: 10px;
                }

            }

}

@media (--menu-expanded) {

    .site-header__logo {
        margin-right: 3vw;
        }

    .site-header__menu-toggle {
        display: none;
        }

        .site-header__nav {
            display: flex;

            & a:hover {
                color: var(--brand-pink);
                }

            }
            .site-header__nav-item {

                &:not(:first-child) {
                    margin-left: 2vw;
                    }

                &:last-child {
                    margin-left: auto;
                    }

                }

}
