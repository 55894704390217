/** ------------------------------
    Reset
    ------------------------------ */

/* Enables fast-tap in iOS Safari when page is zoomed */
a,
button,
input,
textarea,
select,
details {
    touch-action: manipulation;
    }

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    }

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    }

legend {
    margin-bottom: 1em;
    padding-bottom: .33em;
    width: 100%;
    border-bottom: 1px solid var(--form-control-default-border-color:);
    }

textarea {
    resize: vertical;
    }

:matches(img, svg) {
    max-width: 100%;
    vertical-align: middle;
    }

img[width] {
    height: auto;
    }

:matches(ol, ul) {
    list-style: none;
    margin: 0;
    padding-left: 0;
    }
