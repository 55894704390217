/** ------------------------------
    Badges
    ------------------------------ */
[data-badge] {
    position: relative;
    
    &::after {
        content: attr(data-badge);
        position: absolute;
        top: -.125em;
        right: -.125em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        border-radius: 1em;
        background-color: var(--brand-pink);
        color: #fff;
        font-size: .875rem;
        font-family: monospace;
        }
    }

[data-badge="0"] {

    &::after {
        display: none;
        }

    }