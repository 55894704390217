/** ------------------------------
    Base
    ------------------------------ */
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--btn-padding);
    height: var(--btn-height);
    line-height: var(--btn-height);
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;

    &:focus {
        outline: 0;
        }

    &:hover {
        opacity: .9;
        }

    &:matches(:disabled, .disabled) {
        opacity: .7;
        pointer-events: none;
        }
    }
    .btn__icon {
        fill: currentcolor;
        margin-top: -1px;
        margin-right: 8px;
        }


/** ------------------------------
    Groups
    ------------------------------ */
.btn-group {
    display: inline-flex;

    &:not(.btn-group--justify) > :not(:first-child) {
        margin-left: 1rem;
        }
    }

.btn-group--justify {
    width: 100%;
    justify-content: space-between;
    }

.btn-cta {
    margin: 1rem 0;
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--lg {
    height: calc(var(--btn-height) * 1.125);
    line-height: calc(var(--btn-height) * 1.125);
    font-size: 1.125rem;
    font-weight: 400;
    }

.btn--block {
    width: 100%;
    }

.btn--ghost {
    border: 1px solid currentcolor;
    background-color: transparent;
    color: inherit;

    &:matches(:focus, :hover) {
        color: var(--brand-pink);
        opacity: 1;
        }
    }

.btn--link,
.btn--muted {
    height: auto;
    background-color: transparent;
    color: var(--brand-grey);
    font-weight: 400;

    &:matches(:focus, :hover) {
        color: var(--brand-blue);
        }
    }

.btn--link {
    padding: 0;
    }
