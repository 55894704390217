/** ------------------------------
    Photo grid
    ------------------------------ */
.photo-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 10px;

    @media (width >= 520px) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 15px;
        }
    }

.photo {
    border-radius: 4px;
    border: 1px solid #efefef;
    padding: 6px;
    transition: border-color .2s ease-in,
                transform .2s ease-out;

    &:hover {
        transform: scale(1.01);
        box-shadow:
            0 0px 0.7px rgba(0, 0, 0, 0.014),
            0 0.1px 1.9px rgba(0, 0, 0, 0.02),
            0 0.3px 4.5px rgba(0, 0, 0, 0.026),
            0 1px 15px rgba(0, 0, 0, 0.04);

        & .photo__actions {
            opacity: 1;
            }
        }

    &.is-selected {
        border: 3px solid #41b883;
        background-color: #dffff1;
        }
    }
    .photo__img {
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        /* background-color: rgba(0, 0, 0, .03); */

        /* Force 4:3 A/R */
        &::before {
            content: "";
            display: block;
            padding-bottom: calc(3 / 4 * 100%);
            }

        & img {
            position: absolute;
            top: 0;
            height: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            }

        }
        .photo__actions {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, .4);
            color: #fff;
            opacity: 0;
            transition: opacity .2s ease-out;

            & .btn {
                color: currentcolor;
                cursor: pointer;

                &:hover {
                    color: var(--brand-pink);
                    }
                }
            }
    .photo__meta {
        margin-top: 10px;
        font-size: .825em;
        }


/** ------------------------------
    Helpers
    ------------------------------ */
.no-images {
    text-align: center;
    padding: 30px 10px;
    }