/** ------------------------------
    Datepicker customisation
    ------------------------------ */
.vue-daterange-picker {
    display: block !important;
    
    & .btn {
        height: auto;
        line-height: 1.5rem;
        }

    & .btn-success {
        background-color: var(--brand-pink);
        }

    & .in-range {
        background-color: #ff8e98 !important;
        color: #fff !important;
        }

    & .active {
        background-color: var(--brand-pink) !important;
        }

    }
