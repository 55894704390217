/** ------------------------------
    Data Filter
    ------------------------------ */
.filters {
    padding: 15px;
    background-color: #f5f6f8;

    & .btn-group {
        margin-top: 15px;
        }
    }