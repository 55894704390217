/** ------------------------------
    Login
    ------------------------------ */
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @mixin container-gutters;
    padding: 80px 0;
    min-height: 100vh;
    }
    .login__body {
        margin: 40px 0;
        padding: 0 10px;
        width: 100%;
        max-width: 380px;
        }
