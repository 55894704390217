/** ------------------------------
    Panel
    ------------------------------ */
.panel {
    border-radius: 4px;
    border: 1px solid #f5f6f8;
    background-color: #fff;

    & > :matches(.panel__header, .panel__body, .panel__footer) {
        padding: clamp(10px, 2vw, 15px);
        }
    }

    /* Header */
    .panel__header {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        border-bottom: 1px solid #f5f6f8;
        }
        .panel__title {
            margin-bottom: 0;

            &:only-child {
                flex-grow: 1;
                }

            & + span {
                display: inline-block;
                margin-left: .75em;
                font-size: .875rem;
                color: #777;

                @media (width < 600px) {
                    display: none;
                    }
                }
            }
        .panel__header-actions {
            display: flex;
            align-items: center;
            margin-left: auto; /* Align right */

            & > :not(:last-child) {
                margin-right: 10px;
                }
            }

    /* Body */
    .panel__body {

        & > :last-child {
            margin-bottom: 0;
            }

        }

    /* Footer */
    .panel__footer {
        border-top: 1px solid #f5f6f8;
        background-color: var(--brand-light);
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.panel--with-shadow {
    box-shadow:
        0px 0.6px 1.9px rgba(0, 0, 0, .013),
        0px 1.3px 4.3px rgba(0, 0, 0, .019),
        0px 2.4px 7.7px rgba(0, 0, 0, .023),
        0px 4px 12.8px rgba(0, 0, 0, .027),
        0px 6.6px 21.2px rgba(0, 0, 0, .031),
        0px 11.6px 37px rgba(0, 0, 0, .037),
        0px 25px 80px rgba(0, 0, 0, .05);
    }