/** ------------------------------
    Lightbox
    ------------------------------ */
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vw;
    background-color: rgba(0, 0, 0, .83);
    color: #fff;

    & svg {
        fill: currentcolor;
        }
    }
    .lightbox__close {
        @mixin btn-reset;
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        color: #fff;
        }
    .lightbox__media {
        position: relative;
        margin-bottom: 4vh;

        & img {
            display: block;
            width: 100%;
            max-width: 1200px;
            max-height: 75vh;
            }

        & .v-popover {
            position: absolute;
            bottom: 10px;
            right: 10px;
            text-shadow: 0 0 10px rgba(0, 0, 0, .7);
            }
        }