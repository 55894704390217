/** ------------------------------
    Tooltip
    ------------------------------ */
.tooltip {
    display: block !important;
    z-index: 10000;
    font-size: 13px;

    & .tooltip-inner {
        background: #333;
        color: #ccc;
        border-radius: .3em;
        padding: .4em .8em;
        }

    & .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: #333;
        z-index: 1;
        }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        & .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
            }
        }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        & .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
            }
        }

    &[x-placement^="right"] {
        margin-left: 5px;

        & .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
            }
        }

    &[x-placement^="left"] {
        margin-right: 5px;

        & .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
            }
        }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
        }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
        }
    }


/** ------------------------------
    Trigger
    ------------------------------ */
.v-popover {
    width: 24px;
    height: 24px;
    }