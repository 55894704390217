@define-mixin font-scale $value {
    font-size: calc($value / var(--base-font-size) * 1rem);
}

/** ------------------------------
    Base
    ------------------------------ */
html {
    font: var(--base-font-weight) var(--base-font-size-normalized)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

::selection {
    background-color: var(--brand-pink);
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
a {
    color: currentcolor;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    }


/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6 {
    @mixin base-margin;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    line-height: 1.2;
    }

.hd-xl {
    font-size: 2rem;
    }

.hd-lg {
    font-size: 1.5rem;
    }

.hd-md {
    font-size: 1.25rem;
    }

.hd-bd {
    font-size: 1rem;
    }

.hd-sm {
    font-size: .875rem;
    }

.hd-xs {
    font-size: .75rem;
    }


/** ------------------------------
    Content
    ------------------------------ */
p {
    @mixin base-margin;
    }

b,
strong {
    font-weight: 500;
    }

small,
.small {
    font-size: .8em;
    }


/** ------------------------------
    Utilities
    ------------------------------ */
.muted {
    color: var(--brand-grey);
    }

.nl2br {
    white-space: pre;
    }
