/** ------------------------------
    Action sheet
    ------------------------------ */
.action-sheet {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0px 2.2px rgba(0, 0, 0, .021),
                0 0px 5.3px rgba(0, 0, 0, .031),
                0 0px 10px rgba(0, 0, 0, .039),
                0 0px 17.9px rgba(0, 0, 0, .046),
                0 0px 33.4px rgba(0, 0, 0, .055),
                0 0px 80px rgba(0, 0, 0, .07);

    & > :not(:last-child) {
        margin-right: 10px;
        }
    }
