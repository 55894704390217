/** ------------------------------
    Modal
    ------------------------------ */
.modal {
    display: none;

    &:matches(.is-open) {
        display: block;
        }
    }

    /* Overlay */
    .modal__overlay {
        overflow-y: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        }

    /* Container */
    .modal__container {
        width: 95%;
        max-width: 600px;
        border-radius: 2px;
        background-color: #fff;
        }

    /* Header */
    .modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        background-color: var(--brand-blue);
        color: #fff;
        }
        .modal__title {
            margin-bottom: 0;
            @mixin font-scale 18;
            font-weight: 500;
            @mixin text-truncate;
            max-width: calc(100% - 40px);
            }
        .modal__close {
            background: transparent;
            border: 0;
            color: var(--brand-slate);
            font-size: 1.5rem;

            &:matches(:focus, :hover) {
                outline: 0;
                color: #fff;
                }
            }

    /* Body */
    .modal__body {
        padding: 30px;
        text-align: left;

        & :matches(ol, ul) {
            padding-left: 2em;

            & > li:not(:last-child) {
                margin-bottom: .75em;
                }
            }

        & a:not(.btn):hover {
            color: var(--brand-pink);
            }

        & > :last-child {
            margin-bottom: 0;
            }
        }

    /* Footer */
    .modal__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        background-color: var(--brand-light);
        }

/** ------------------------------
    Modifiers
    ------------------------------ */
.modal__container--wide {
    max-width: 800px;
    }
