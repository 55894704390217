/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }


/** ------------------------------
    Containers
    ------------------------------ */
@define-mixin container {
    margin: auto;
    @mixin container-width;
    @mixin container-gutters;
}

@define-mixin container-width {
    max-width: var(--container-width);
}

@define-mixin container-width-md {
    max-width: calc(var(--container-width) * .8);
}

@define-mixin container-width-sm {
    max-width: calc(var(--container-width) * .6);
}

@define-mixin container-width-fluid {
    max-width: none;
}

@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);

    @media (width < 480px) {
        padding: 0 8px;
        }
}

@define-mixin negative-gutters {
    margin-left: calc(var(--container-gutter) / -2);
    margin-right: calc(var(--container-gutter) / -2);

    @media (width < 480px) {
        margin-left: -8px;
        margin-right: -8px;
    }
}

.container {
    @mixin container;
    }

.container--md {
    @mixin container-width-md;
    }

.container--sm {
    @mixin container-width-sm;
    }

.container--fluid {
    @mixin container-width-fluid;
    }

.is-contained {
    @mixin container-width;
    }


/** ------------------------------
    App
    ------------------------------ */
#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    }

.main-content {
    padding: 50px 0;

    @media (width <= 600px) {
        padding-top: 30px;
        }
    }
